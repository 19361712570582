// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA0YWD46YO9VI0z9NGDiLfCAv-Fi7FSlr8",
    authDomain: "cslewislive.firebaseapp.com",
    projectId: "cslewislive",
    storageBucket: "cslewislive.appspot.com",
    messagingSenderId: "81242656370",
    appId: "1:81242656370:web:fce14c622a2ba0a566bceb"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
